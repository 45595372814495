import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { Box } from '@core';
import { CardList, SectionContainerSC as SectionContainer, TabsSlider, Text } from '@components';
import { REVIEW_ITEMS } from '@shared/constants/pages/product-page-common';
import { trackCustomGA4Event } from '@shared/google-utils';
import { GA4_EVENTS } from '@shared/constants/google';

const LabelComponent = ({ label, isOpen }) => (
  <Box
    position="relative"
    bottom="-3px"
    borderBottom="4px solid"
    borderColor={isOpen ? 'darkBlue' : 'transparent'}
    width={{ _: 136, md: 160 }}
    display="flex"
    justifyContent="center"
    mx={12}
  >
    <Text.Body2Strong pb={8} color={isOpen ? 'darkBlue' : 'darkBlue_40'}>
      {label}
    </Text.Body2Strong>
  </Box>
);

const ContentComponent = ({
  content,
  cardComponent: CardComponent,
  onSlideBefore,
  onSlideAfter,
}) => (
  <Box display="flex" minWidth="100%" maxWidth="100%">
    <CardList
      items={content}
      itemComponent={({ customComponent: CustomComponent, ...props }) =>
        CustomComponent ? <CustomComponent {...props} /> : <CardComponent {...props} />
      }
      itemsOnDesktop={3}
      sliderProps={{
        indicatorComponent: () => null,
        onSlideBefore,
        onSlideAfter,
      }}
    />
  </Box>
);

const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 4px;
  overflow-x: auto;
`;

const Reviews = ({
  title,
  description,
  items = REVIEW_ITEMS,
  padding = { py: { _: 64, md: 88 } },
}) => {
  const [openTab, setOpenTab] = useState(0);

  const wrapperRef = useCallback(
    (ref) => {
      if (ref) {
        ref.scrollLeft = openTab * 120;
      }
    },
    [openTab]
  );

  const onSlideBefore = () => {
    const nextTab = openTab > 0 ? openTab - 1 : 0;
    if (nextTab !== openTab) {
      setOpenTab(nextTab);
    }
  };

  const onSlideAfter = () => {
    const nextTab = openTab < items.length - 1 ? openTab + 1 : openTab;
    if (nextTab !== openTab) {
      setOpenTab(openTab < items.length - 1 ? openTab + 1 : openTab);
    }
  };

  const data = items.map(({ label, cardComponent, content }) => ({
    labelProps: {
      label,
    },
    contentProps: {
      cardComponent,
      content,
      onSlideBefore,
      onSlideAfter,
    },
  }));

  return (
    <SectionContainer
      data-section-id="Reviews"
      title={title}
      description={description}
      wrapperProps={{ ...padding }}
    >
      <TabsSlider
        headerWrapper={({ children }) => (
          <Wrapper ref={wrapperRef}>
            <Box width="100%" minWidth={640} borderBottom="2px solid #D4D5DB" display="flex">
              <Box mx="auto" display="flex">
                {children}
              </Box>
            </Box>
          </Wrapper>
        )}
        tabsData={data}
        onOpen={(item) =>
          trackCustomGA4Event({ eventName: GA4_EVENTS.experiencesTabClick(item + 1) })
        }
        labelComponent={LabelComponent}
        contentComponent={ContentComponent}
        externalOpenedTab={openTab}
        openExternal={(index) => setOpenTab(index)}
      />
    </SectionContainer>
  );
};

export default Reviews;
